import { GLOBALTYPES } from "./globalTypes";
import { imageUpload } from "../../utils/imageUpload";
import { getDataAPI, postDataAPI } from "../../utils/fetchData";

export const STORY_TYPES = {
  CREATE_STORY: "CREATE_STORY",
  LOADING_STORY: "LOADING_STORY",
  GET_STORIES: "GET_STORIES",
};

export const createStory =
  ({ content, media, auth }) =>
  async (dispatch) => {
    try {
      dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });

      let mediaUrl = null;
      if (media) {
        console.log("Uploading media to Cloudinary...");
        const uploadResult = await imageUpload([media]); 
        console.log("Cloudinary upload result:", uploadResult);
        mediaUrl = uploadResult[0]?.url;
      }

      const payload = { content, mediaUrl }; 
      console.log("Payload being sent to backend:", payload);

      const res = await postDataAPI("addStory", payload, auth.token);

      dispatch({
        type: STORY_TYPES.CREATE_STORY,
        payload: { ...res.data.newStory, user: auth.user },
      });

      dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: false } });
    } catch (err) {
      console.error("Error in createStory:", err.response?.data || err.message);
      dispatch({
        type: GLOBALTYPES.ALERT,
        payload: {
          error: err.response?.data?.msg || "Failed to create story.",
        },
      });
    }
  };

export const getStories = (auth) => async (dispatch) => {
  try {
    dispatch({ type: STORY_TYPES.LOADING_STORY, payload: true });

    const res = await getDataAPI("stories", auth.token);
    console.log("Data fetch ",res);
    

    dispatch({
      type: STORY_TYPES.GET_STORIES,
      payload: res.data.stories,
    });

    dispatch({ type: STORY_TYPES.LOADING_STORY, payload: false });
  } catch (err) {
    dispatch({
      type: GLOBALTYPES.ALERT,
      payload: {
        error: err.response?.data?.msg || "Failed to fetch stories.",
      },
    });
  }
};
