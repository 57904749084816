import React from 'react'
import StoryList from './Storylist'

const MainSection = () => {
  return (
    <div className="MainBox" >
      <StoryList />
    </div>
  );
}

export default MainSection
