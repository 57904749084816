import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../redux/actions/authAction";
import { useDispatch, useSelector } from "react-redux";

const Login = () => {
  const initialState = { email: "", password: "" };
  const [userData, setUserData] = useState(initialState);
  const { email, password } = userData;

  const [typePass, setTypePass] = useState(false);

  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const { auth } = useSelector((state) => state);

  useEffect(() => {
    if (auth.token) {
      Navigate("/");
    }
  }, [auth.token, Navigate]);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(userData);
    dispatch(login(userData));
  };

  return (
    <div className="auth_page">
      <form onSubmit={handleSubmit}>
        <h3
          className="text-uppercase text-center mb-4"
          style={{
            fontFamily: "Arial",
            fontSize: "48px",
            fontWeight: "bold",
            color: "#4A90E2",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
            transition: "color 0.3s ease",
          }}
        >
          ARTalk
        </h3>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Email address</label>
          <input
            type="email"
            className="form-control"
            id="exampleInputEmail1"
            onChange={handleChangeInput}
            value={email}
            name="email"
          />
          {/* aria-describedby="emailHelp" */}
          <small id="emailHelp" className="form-text text-muted">
            We'll never share your email with anyone else.
          </small>
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputPassword1">Password</label>

          <div className="pass">
            <input
              type={typePass ? "text" : "password"}
              className="form-control"
              id="exampleInputPassword1"
              onChange={handleChangeInput}
              value={password}
              name="password"
            />
            <small onClick={() => setTypePass(!typePass)}>
              {typePass ? "Hide" : "Show"}
            </small>
          </div>
        </div>

        <button
          type="submit"
          className="btn btn-primary w-100"
          disabled={email && password ? false : true}
        >
          Login
        </button>

        <p className="my-2">
          You don't have an account?{" "}
          <Link to="/register" style={{ color: "blue" }}>
            Register Now!
          </Link>
        </p>
        <p className="my-2">
          If you forgate?{" "}
          <Link to="/emailsend" style={{ color: "blue" }}>
            Reset Now!
          </Link>
        </p>
      </form>
    </div>
  );
};

export default Login;
