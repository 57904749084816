import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GLOBALTYPES } from "../../redux/actions/globalTypes";
import { createStory } from "../../redux/actions/storyAction";

const StoryCreate = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);

  const [content, setContent] = useState(""); 
  const [media, setMedia] = useState(null); 
  const [preview, setPreview] = useState(""); 

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 17 * 1024 * 1024) {
        dispatch({
          type: GLOBALTYPES.ALERT,
          payload: { error: "File size must be less than 17MB." },
        });
        return;
      }
      setMedia(file);
      setPreview(URL.createObjectURL(file)); 
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!media && !content.trim()) {
      return dispatch({
        type: GLOBALTYPES.ALERT,
        payload: { error: "Please add content or media to your story." },
      });
    }

    dispatch(createStory({ content, media, auth }));

    setContent("");
    setMedia(null);
    setPreview("");
  };

  const isButtonActive = !!media;

  return (
    <div className="story-create">
      <div className={`media-section ${media ? "active" : ""}`}>
        <input
          type="file"
          id="file"
          accept="image/*,video/*"
          onChange={handleFileChange}
          className="file-input"
        />
        {preview ? (
          <div className="media-preview-container">
            {media && media.type.startsWith("video") ? (
              <video controls className="media-video">
                <source src={preview} type={media.type} />
              </video>
            ) : (
              <img src={preview} alt="preview" className="media-img" />
            )}
            <span
              onClick={() => setMedia(null) || setPreview("")}
              className="delete-media-btn"
            >
              &times;
            </span>
          </div>
        ) : (
          <label htmlFor="file" className="btn">
            Select Media
          </label>
        )}

        <textarea
          name="content"
          value={content}
          placeholder="Write something..."
          onChange={(e) => setContent(e.target.value)}
          className="text-input"
        />
        <button
          className="submit-plus-icon"
          onClick={handleSubmit}
          disabled={!media}
        >
          <i className="fas fa-plus"></i>
        </button>
      </div>
    </div>
  );
};

export default StoryCreate;
