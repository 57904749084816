import React from 'react'
import StoryList from './Storylist'
import StoryCreate from './StoryCreate';

const MainSection = () => {
  return (
    <div className="MainBox" style={{display:'flex'}}>
      <StoryCreate />
      <StoryList />
    </div>
  );
}

export default MainSection
