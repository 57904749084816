import { STORY_TYPES } from "../actions/storyAction";

const initialState = {
  loading: false,
  stories: [],
  result: 0,
  detailStory: [],
};

const storyReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORY_TYPES.LOADING_STORY:
      return {
        ...state,
        loading: action.payload,
      };

    case STORY_TYPES.CREATE_STORY:
      return {
        ...state,
        stories: [action.payload, ...state.stories],
      };

    case STORY_TYPES.GET_STORIES:
      return {
        ...state,
        stories: action.payload,
        result: action.payload.length,
      };

    default:
      return state;
  }
};

export default storyReducer;
