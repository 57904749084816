import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStories } from "../../redux/actions/storyAction";

const StoryList = () => {
  const dispatch = useDispatch();
  const { stories, loading } = useSelector((state) => state.story);
  const { auth } = useSelector((state) => state);

  const [selectedStory, setSelectedStory] = useState(null); 

  useEffect(() => {
    if (auth?.token) {
      dispatch(getStories(auth));
    }
  }, [dispatch, auth]);

  const handleCloseModal = () => setSelectedStory(null); 

  if (loading) return <div></div>;

  const handleStoryClick = (story) => {
    setSelectedStory(story); 
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(" "); 
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "..."; 
    }
    return text; 
  };


  return (
    <div className="story-list">
      {stories?.length > 0 ? (
        stories.map((story) => (
          <div
            key={story._id}
            className="story-item"
            onClick={() => handleStoryClick(story)}
          >
            <div className="media-preview">
              {story.mediaUrl && (
                <div
                  className="media-content"
                  style={{
                    backgroundImage: story.mediaUrl.match(/video/i)
                      ? `url(${story.mediaUrl})`
                      : `url(${story.mediaUrl})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  {story.mediaUrl.match(/video/i) && (
                    <div className="play-button">▶</div>
                  )}
                </div>
              )}
            </div>
            {/* Username */}
            {/* <span className="username">{story.username}</span> */}
            {/* Content */}
            <div className="story-content">
              {truncateText(story.content, 4)}
            </div>
          </div>
        ))
      ) : (
        <div></div>
      )}

      {selectedStory && (
        <div className="story-modal">
          <div className="modal-content">
            <button className="close-btn" onClick={handleCloseModal}>
              &times;
            </button>
            <div className="modal-media">
              {selectedStory.mediaUrl && (
                <>
                  {selectedStory.mediaUrl.match(/video/i) ? (
                    <video
                      controls
                      className="modal-video"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    >
                      <source src={selectedStory.mediaUrl} type="video/mp4" />
                    </video>
                  ) : (
                    <img
                      src={selectedStory.mediaUrl}
                      alt="story"
                      className="modal-img"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  )}
                </>
              )}
            </div>
              <h3>{selectedStory.user.fullname}</h3>
            <div className="modal-details">
              <p>{selectedStory.content}</p>
              <span>
                {new Date(selectedStory.storyData).toLocaleString("en-IN", {
                  timeZone: "Asia/Kolkata",
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                })}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StoryList;
